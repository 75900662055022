import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "tradition",
  casinoId: 36,
  GA_TRACKING:'G-ED723FTJXX',

  rivalChatGroupName: "Gibson",
  prettyName: "Gibson Casino",
  contactEmail: "support@gibsoncasino.com",
  docsEmail: "documents@gibsoncasino.email",

  assetsPath: "assets/brands/gibson",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/brands/gibson/promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/gibson/promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/gibson/promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/brands/gibson/promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/brands/gibson/promo.png"}
  ],


  //SEO
  metaDescription: "Online casino gaming world at Gibson Casino with over 200 gambling games, including slots, table games, jackpot machines, baccarat and live dealer experiences. Real cash bonuses, deposit and entry bonus, rewarding loyalty program and secure transactions. Play anytime and hit the big money prize!",
  logoName: "gibson-casino-online-casino-logo.png",
  logoAltText: "Gibson Casino online logo with bold gold text known for luxury slots high-stakes table games and exclusive casino rewards."
};


